@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  @apply
  bg-white md:bg-light-blue
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /*overflow: hidden;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply
  bg-white md:bg-light-blue
}

#root {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  @apply
  bg-white md:bg-light-blue
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 16px;
  font-weight: 500;
  color: #343434;
}

h1 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #343434;
}

label {
  color: #343434;
}

span {
  @apply
  text-blue
}

.btn-next {
  @apply
  rounded-lg uppercase w-full text-white p-[.75rem] bg-blue text-base font-bold mb-[2rem]
}

.form-fields {
  @apply
  bg-white flex flex-col gap-[1.5rem]
}

.PhoneInputCountry {
  display: none;
}

.PhoneInputInput {
  @apply
  rounded-lg p-[.75rem] border
}